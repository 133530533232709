import { makeStyles } from "@material-ui/core";
import * as singleSpa from "single-spa";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { Button, ButtonLoader, SnackbarBanner, Paragraph, TooltipV2 } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useCaptureEventsV2, UUID } from "gx-npm-lib";
import { IconCheckMarkComplete, IconCheckMarkNotComplete } from "../../ui/icons/form.icons";
import useFormSubmit from "../form/context/submit/use-form-submit.hook";
import DialogConfirmCancel from "./dialog-cancel-setup-confirmation.component";
import DialogUpdateExistingSetupConfirmation from "./dialog-update-existing-setup-confirmation.component";
import styles from "./footer-action-bar.styles";
import { ClientEvent } from "../../app.constants";

type FooterActionBarProps = { initId?: UUID; surveyId?: UUID };

const useStyles = makeStyles(() => styles);
const FooterActionBarComponent = ({ initId = "", surveyId = "" }: FooterActionBarProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleFormSubmit, hasSurveyRecipientViewed, hasUpdates, isFormComplete } = useFormSubmit();
  const [previewWindow, setPreviewWindow] = useState<Window | null>(null);
  const [isSaveError, setIsSaveError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpenCancelSetup, setIsOpenCancelSetup] = useState(false);
  const [isOpenUpdateExisting, setIsOpenUpdateExisting] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const navigateToInitiatives = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initId}/questionnaire`);
    handleSetupClose();
  };
  const captureEventsV2 = useCaptureEventsV2();

  const handleClickCancel = () => {
    if (hasUpdates) {
      setIsOpenCancelSetup(true);
    } else {
      navigateToInitiatives();
    }
  };

  const handleClickSaveSetup = () => {
    if (hasSurveyRecipientViewed) {
      setIsOpenUpdateExisting(true);
    } else {
      handleSave();
    }
  };

  const handleCloseUpdateExisting = (isConfirmed: boolean) => {
    if (isConfirmed) {
      handleSave();
    } else {
      setIsOpenUpdateExisting(false);
    }
  };

  const handleCloseCancelSetup = (isConfirmed: boolean) => {
    setIsOpenCancelSetup(false);
    if (isConfirmed) {
      navigateToInitiatives();
    }
  };

  const handlePreviewClick = () => {
    const event = {
      eventType: ClientEvent.SURVEY_SETUP_PREVIEW_CLICKED,
      metaData: {
        initiativeId: initId,
        surveyId: surveyId || "",
      },
    };
    captureEventsV2([event]);
    if (!previewWindow || previewWindow.closed) {
      const newPreviewWindow = window.open(`${window.location.pathname}/preview/overview`, "_blank");
      setPreviewWindow(newPreviewWindow);
    } else {
      previewWindow.focus();
    }
    window.onbeforeunload = () => handleSetupClose();
  };

  const handleSetupClose = () => {
    if (previewWindow) {
      previewWindow.close();
      setPreviewWindow(null);
    }
  };

  const handleSave = () => {
    setIsSaving(true);
    const onError = () => {
      setIsSaveError(true);
      setTimeout(() => setIsSaveError(false), 3000);
    };
    const onSuccess = () => {
      setIsOpenUpdateExisting(false);
      navigateToInitiatives();
    };
    handleFormSubmit({ initId, surveyId }, onSuccess, onError).finally(() => setIsSaving(false));
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolledToBottom(
        window.innerHeight + document.documentElement.scrollTop === document.documentElement.scrollHeight
      );
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section
      aria-label={t("Footer action bar")}
      className={classNames(classes.root, !scrolledToBottom && classes.shadow)}
    >
      <div className={classes.left}>
        <div aria-label="Questionnaire progress" className={classNames(classes.progress, isFormComplete && "complete")}>
          {isFormComplete ? <IconCheckMarkComplete /> : <IconCheckMarkNotComplete backgroundColor={"transparent"} />}
          <Paragraph boldness="semi" type="p3">
            {isFormComplete ? t("Setup complete") : t("Setup in progress")}
          </Paragraph>
        </div>
      </div>
      <div className={classes.right}>
        <div aria-label="Questionnaire action buttons">
          <Button
            disabled={isSaving}
            onClick={handleClickCancel}
            rootClassName={classNames("gx-btn-survey-setup-cancel", "btn-tertiary")}
          >
            {t("Cancel")}
          </Button>

          <TooltipV2
            deactivate={isFormComplete}
            placement="top"
            title={t("Complete your setup to preview your questionnaire.")}
            PopperProps={{ modifiers: { offset: { offset: "0, 22" } } }}
            rootClassName={classes.textToolTip}
          >
            <span>
              <Button
                disabled={!isFormComplete}
                onClick={handlePreviewClick}
                rootClassName={classNames("btn-secondary", classes.previewBtn)}
              >
                {t("Preview")}
              </Button>
            </span>
          </TooltipV2>

          <ButtonLoader
            btnClass="btn-primary"
            btnRootClassName={classNames("gx-btn-survey-setup-save", surveyId && "gx-btn-survey-setup-save-edit")}
            disabled={!isFormComplete || (!!surveyId && !hasUpdates)}
            isLoading={isSaving}
            onClick={handleClickSaveSetup}
            rootClassName={classes.save}
          >
            {t("Save questionnaire setup")}
          </ButtonLoader>
        </div>
      </div>
      <DialogUpdateExistingSetupConfirmation
        isOpen={isOpenUpdateExisting}
        isSaving={isSaving}
        onClose={handleCloseUpdateExisting}
      />
      <DialogConfirmCancel isOpen={isOpenCancelSetup} onClose={handleCloseCancelSetup} />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={isSaveError} />
    </section>
  );
};

export default FooterActionBarComponent;
