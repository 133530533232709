import { colorPalette } from "gx-npm-common-styles";

const colorCherryHex = colorPalette.status.cherry.hex;
const colorPoisonCherryHex = colorPalette.status.poisonCherry.hex;
const colorStone = colorPalette.neutrals.stone.hex;

const surveyFormStyles = {
  root: {
    padding: "48px 56px",
    "& $header": {
      borderBottom: `1px solid ${colorStone}`,
      display: "flex",
      marginBottom: 72,
      paddingBottom: 12,
      "& $required": {
        color: colorPoisonCherryHex,
        marginLeft: "auto",
        "&:before": {
          content: "'*'",
          color: colorCherryHex,
          paddingRight: 5,
        },
      },
    },
  },
  header: {},
  required: {},
};

export { surveyFormStyles };
