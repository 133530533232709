import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { requestDocumentsStyles as styles } from "./request-documents.styles";
import { Paragraph } from "gx-npm-ui";
import FormSection from "../form-section/form-section.component";
import FormSectionAbout from "../form-section/form-section-about.component";
import FormSectionLeft from "../form-section/form-section-left.component";
import FormSectionRight from "../form-section/form-section-right.component";
import RequestDocumentsList from "./request-documents-list.component";

const useStyles = makeStyles(() => styles);
const RequestDocumentsComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div aria-label={t("request documents")}>
      <FormSection>
        <FormSectionLeft>
          <FormSectionAbout
            title={t("Request documents")}
            description={t("Ask recipients to provide documents that support your teams evaluation requirements.")}
          />
        </FormSectionLeft>
        <FormSectionRight>
          <Paragraph boldness="semi" rootClassName={classes.requestedDocuments}>
            {t("Requested documents")}
          </Paragraph>
          <RequestDocumentsList />
        </FormSectionRight>
      </FormSection>
    </div>
  );
};

export default RequestDocumentsComponent;
