import { makeStyles, Fade } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Header,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import CompanyNameInstructionsComponent from "./components/company-name-instructions/company-name-instructions.component";
import QuestionsCategoriesComponent from "./components/questions-categories/questions-categories.component";
import RequestDocumentsComponent from "./components/request-documents/request-documents.component";
import ResponseOptionsComponent from "./components/response-options/response-options.component";
import { surveyFormStyles as styles } from "./survey-form.styles";
import { useEffect, useState } from "react";
import { RequirementCategoryDto, RequirementItemType, SurveyPreviewData } from "./form.types";
import { useFormContext } from "./context/form.context";
import { useParams } from "react-router-dom";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flags";
import classNames from "classnames";

const useStyles = makeStyles(() => styles);
const LOCAL_STORE_PREVIEW_KEY = "survey_setup";

const SurveyFormComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const { availableCategories, companyName, instructions, requestedDocuments, responseOptions, selectedCategories } =
    useFormContext();
  const { initiativeId } = useParams();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const selectedCategoriesForPreview: RequirementCategoryDto[] = [];
    let requirementsCount = 0;
    availableCategories.forEach((category) => {
      if (selectedCategories.includes(category.id)) {
        requirementsCount += category.itemCount;
        selectedCategoriesForPreview.push({
          id: category.id,
          name: category.name,
          items: (category.items || []).map((item: RequirementItemType) => ({
            ...item,
            responseOptionId: "",
            comment: "",
          })),
          comment: "",
        });
      }
    });
    const localStorageData: SurveyPreviewData = {
      companyName,
      instructions,
      requestedDocuments: requestedDocuments.filter((doc) => !doc.isDeleted),
      selectedCategories: selectedCategoriesForPreview,
      responseOptions: responseOptions.filter((option) => !option.isDeleted),
      isValid:
        !!companyName &&
        !!instructions &&
        responseOptions.filter((option) => !option.isDeleted).length > 0 &&
        selectedCategories.length > 0,
      requirementsCount,
      initiativeId: initiativeId || "",
    };
    localStorage.setItem(LOCAL_STORE_PREVIEW_KEY, JSON.stringify(localStorageData));
    window.onbeforeunload = () => localStorage.removeItem(LOCAL_STORE_PREVIEW_KEY);
    return () => localStorage.removeItem(LOCAL_STORE_PREVIEW_KEY);
  }, [
    availableCategories,
    companyName,
    initiativeId,
    instructions,
    requestedDocuments,
    responseOptions,
    selectedCategories,
  ]);

  return (
    <Fade in={show}>
      <section aria-label={t("Questionnaire form")} className={classes.root}>
        <div className={classes.header}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent styling={"h5"}>{t("Questionnaire form")}</TypographyComponent>
              <TypographyComponent
                styling={"p1"}
                color={"poisonCherry"}
                rootClassName={classNames(classes.GCOM3606required)}
              >
                {t("Indicates required")}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Header type="h5">{t("Questionnaire form")}</Header>
              <Paragraph rootClassName={classes.required} type="p1">
                {t("Indicates required")}
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <CompanyNameInstructionsComponent />
        <QuestionsCategoriesComponent />
        <ResponseOptionsComponent />
        <RequestDocumentsComponent />
      </section>
    </Fade>
  );
};

export default SurveyFormComponent;
