import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { formSectionAboutStyles as styles } from "./form-section-about.styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Header,
  Paragraph,
  TypographyComponent,
} from "gx-npm-ui";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";

type FormSectionAboutProps = {
  description?: string;
  rootClassName?: string;
  title?: string;
};

const useStyles = makeStyles(() => styles);

const FormSectionAbout = ({ description = "", rootClassName = "", title = "" }: FormSectionAboutProps) => {
  const classes = useStyles();

  return (
    <div aria-label={"title and description"} className={classNames(classes.container, rootClassName && rootClassName)}>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent styling={"h4"} color={"carbon"} rootClassName={classNames(classes.GCOM3606title)}>
            {title}
          </TypographyComponent>
          <TypographyComponent
            element={"p"}
            boldness={"regular"}
            color={"iron"}
            rootClassName={classNames(classes.GCOM3606description)}
          >
            {description}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <Header rootClassName={classes.title} type="h4">
            {title}
          </Header>
          <Paragraph rootClassName={classes.description}>{description}</Paragraph>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </div>
  );
};

export default FormSectionAbout;
