import { colorPalette } from "gx-npm-common-styles";

const coalColor = colorPalette.neutrals.coal.hex;

const requestDocumentsStyles = {
  requestedDocuments: {
    color: coalColor,
    paddingBottom: 26,
  },
};

const requestDocumentsListStyles = {
  root: {},
};

const requestDocumentsListDeleteButtonStyles = {
  root: {
    marginLeft: "auto",
  },
};

export { requestDocumentsStyles, requestDocumentsListStyles, requestDocumentsListDeleteButtonStyles };
