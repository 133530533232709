import React, { useEffect, useRef, useState } from "react";
import { Loader, Paragraph, TextLink, TypographyComponent } from "gx-npm-ui";
import styles from "./overview.styles.module.scss";
import * as videoPlayer from "@vidyard/embed-code";
import { useTranslation } from "react-i18next";
import { TimerIcon } from "gx-npm-icons";
import classNames from "classnames";

const OverviewComponent = () => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const player = await videoPlayer.api.renderPlayer({
          uuid: "kaTaDgitDqmMuGEEJygJ87",
          container: videoRef.current,
        });
        player.on("ready", () => {
          setIsLoading(false);
        });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [videoRef]);

  return (
    <div>
      <TypographyComponent type={"h5"} boldness={"semi"}>
        {t("Overview")}
      </TypographyComponent>
      <div className={styles.mediaContainer}>
        <div ref={videoRef} className={styles.media}>
          {isLoading && (
            <div aria-label={"loader"} className={classNames(styles.loader)}>
              <Loader size={48} />
            </div>
          )}
        </div>
        <div className={styles.mediaDesc}>
          <TypographyComponent type={"p3"} boldness={"medium"} rootClassName={styles.mediaContent}>
            {t("Watch a quick overview of how questionnaires work in BuySmart")}
          </TypographyComponent>
          <Paragraph rootClassName={styles.mediaTime} type={"p3"} boldness={"regular"}>
            <TimerIcon className={styles.timerIcon} />
            1:29
          </Paragraph>
        </div>
      </div>
      <div className={styles.description}>
        <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={"semi"}>
          {t(
            "With vendor questionnaires, you can turn requirement categories into questions and send them to your vendor contacts in order to learn more about their product."
          )}
        </TypographyComponent>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about vendor questionnaires")}
            target={"_blank"}
            href={
              "https://intercom.help/gartner-buysmart-help-center/en/articles/8214167-intro-to-buysmart-questionnaires-for-evaluation-teams"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewComponent;
