import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FormEvent } from "react";
import { Checkbox, Paragraph } from "gx-npm-ui";
import useFormSelectedCategories from "../../context/use-form-selected-categories.hook";
import { questionsCategoriesListStyles as styles } from "./questions-categories-list.styles";
import QuestionsCategoriesListItemComponent from "./questions-categories-list-item.component";

const useStyles = makeStyles(() => styles);
const QuestionsCategoriesListComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    availableCategories,
    handleChangeSelectAll,
    handleChangeCategorySelected,
    isAllSelected,
    totalCategoriesCount,
    totalRequirementsCount,
    verifyIsCategorySelected,
  } = useFormSelectedCategories();

  return (
    <div aria-label={t("list of categories and number of requirements")} className={classes.categoriesListRoot}>
      <Paragraph boldness="semi" rootClassName={classes.categoriesRequirement}>
        {t("Categories to include in the questionnaire")}
      </Paragraph>
      <Checkbox
        checked={isAllSelected}
        inputProps={{ "aria-label": t("Select all categories") }}
        label={t("Select all")}
        onChange={(event: FormEvent<HTMLInputElement>) => handleChangeSelectAll(event.currentTarget.checked)}
        rootClassName={classes.checkBoxSelectAll}
      />

      <ul className={classes.categoriesList}>
        {availableCategories.map((category) => {
          return (
            <QuestionsCategoriesListItemComponent
              category={category}
              isSelected={verifyIsCategorySelected(category.id)}
              key={`category-${category.id}`}
              onCategoryListItemChange={() => handleChangeCategorySelected(category.id)}
            />
          );
        })}
      </ul>

      <div className={classes.categoriesSelected}>
        <Paragraph boldness="semi">
          <span className={classes.categoriesSelectedCount}>{totalCategoriesCount}</span>
          {` ${t("Categories selected")}`}
        </Paragraph>
        <Paragraph boldness="semi">
          <span className={classes.requirementsSelectedCount}>{totalRequirementsCount}</span>
          {` ${t("Requirements included")}`}
        </Paragraph>
      </div>
    </div>
  );
};

export default QuestionsCategoriesListComponent;
